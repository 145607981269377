import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'
import Slider from 'react-slick'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Col, Row, Container} from 'react-bootstrap'

import { useWindowDimensions } from '@modules/useWindowDimensions'

// components
import ButtonPrev from '@components/ButtonPrev'
import ButtonNext from '@components/ButtonNext'

const BOXSPACING = 32

const CarouselBodyButton = styled(({ className, data }) => {
  const { width: windowSize } = useWindowDimensions()

  const refWhy = useRef(null)
  const refMain = useRef(null)

  const [whyWrapperWidth, setWhyWrapperWidth] = useState({})
  const [mainWrapperWidth, setMainWrapperWidth] = useState({})
  const [mainGap, setMainGap] = useState({})

  useEffect(() => {
    const articlesCurrentWidth = refWhy.current.clientWidth

    const mainPosition = (whyWrapperWidth - refMain.current.clientWidth) / 2
    const mainCurrentWidth = refMain.current.clientWidth + mainPosition

    setWhyWrapperWidth(articlesCurrentWidth)

    setMainWrapperWidth(mainCurrentWidth)
    setMainGap(mainPosition)
  }, [windowSize, whyWrapperWidth, refMain])

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <ButtonPrev />,
    nextArrow: <ButtonNext />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.4,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  }

  return (
    <div className={`${className} w-100`} ref={refWhy}>
      <Container>
        <Row>
          <Col>
            <div ref={refMain}>
              <div
                className="limit overflow-hidden"
                css={css`
                  @media (min-width: 568px) and (max-width: 768px) {
                    right: -${mainGap}px;
                    width: ${mainWrapperWidth + BOXSPACING}px;
                  }
                `}
              >
                <div className="w-100">
                  <Slider {...settings}>
                    {data.card.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            className={`position-relative w-100`}
                            key={index}
                          >
                            <Link
                              to={item.link}
                              className="d-flex flex-column position-relative link"
                            >
                              <div className="image-box">
                                <Img
                                  className="w-100 zoomable"
                                  fluid={
                                    item.image.imageFile.childImageSharp.fluid
                                  }
                                  alt={
                                    item.image.altText
                                      ? item.image.altText
                                      : item.imagealt
                                  }
                                />
                              </div>
                              <div className="text-box">
                                <h5 className="text-black font-20 font-weight-normal">
                                  {item.title}
                                </h5>
                                <p>{item.text}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`

  .slick-slider {
    margin-left: -15px;
    margin-right: -15px;

    .slick-track {
      overflow: visible;

      .slick-slide {
        margin-right: 0;

        > div {
          background: transparent !important;
          padding: 0 15px;

          @media (min-width: 992px) {
            padding: 20px 12px;
          }

          .link {
            background-color: #fff;
            background-clip: border-box;
            border-radius: 0;
            min-height: 342px;
            min-width: 0;
            text-decoration: none !important;
            transition: all 100ms linear;
            word-wrap: break-word;

            @media (min-width: 769px) {
              min-height: 351px;

              &:hover {
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
                transform: scale(1.05);
              }
            }

            .image-box {
              overflow: hidden;
              position: relative;
              width: 100%;

              .zoomable {
                display: block;
                height: auto;
                max-width: 100%;
                transform: scale(1);
                transition: all 0.3s;
                width: 100%;
                height: 182px;
                object-fit: cover;
              }
            }

            .text-box {
              padding: 36px 36px 0 15px;

              @media (min-width: 568px) {
                padding: 26px 10px 0 10px;
              }

              @media (min-width: 768px) {
                padding: 36px 36px 0 15px;
              }

              h5 {
                margin-bottom: 15px;
              }

              p {
                color: #8e8e8e;
                font-size: 12px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.8px;
                line-height: 1.33;
                margin-bottom: 0;
              }
            }

            p {
              @media (min-width: 768px) {
                min-height: 100%;
              }
            }
          }
        }

        &:slick-current {
          > div {
            background: transparent !important;
          }
        }
      }
    }

    .slick-dots {
      left: 15px;
      margin-bottom: 0;

      li {
        height: 10px;
        width: 10px;
        button {
          height: 10px;
          width: 10px;
          &:before {
            font-size: 30px;
            height: 10px;
            line-height: 10px;
            width: 10px;
          }
        }
      }
    }

    .slick-arrow {
      bottom: -72px;
      margin: 0 15px;
    }
  }
`

CarouselBodyButton.propTypes = {
  data: object.isRequired
}

CarouselBodyButton.defaultProps = {
  data: []
}

export default CarouselBodyButton

import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import Layout from '@compositions/Layout'
import Hr from '@components/Hr'
import SEO from '@components/SEO'
import CarouselBodyButton from './components/CarouselBodyButton'
import HeroSmall from './components/HeroSmall'
import TeamCategory from './components/TeamCategory'
import ProjectForm from '@components/ProjectForm'

// graphql
import { teamContent } from './modules/graphql'
import { Image } from 'react-bootstrap'

const Team = styled(({ className }) => {
  if (!teamContent()) {
    if (typeof window !== 'undefined' && window) {
      window.location.href = '/'
    }
    return <></>
  }

  const seoData = teamContent()[0]
  const heroData = teamContent()[1]
  const techBannerData = teamContent()[2]
  const cardsStandardData = teamContent()[3]
  const teamCategories = teamContent()[4]
  const projectFormData = teamContent()[5]

  return (
    <Layout className={`${className}`}>
      <SEO title={seoData.title} description={seoData.description} />
      {/* Hero */}
      <div className="">
        <div className="d-inline-block w-100">
          <div className="d-inline-block w-100">
            <HeroSmall data={heroData} />
          </div>
        </div>
      </div>
      {/* Team members */}
      <TeamCategory teamCategories={teamCategories} />
      {/*
      <div ref={teamContainer}>
        <div className="mb-md-3 d-inline-block w-100">
          <div className="mb-5 d-inline-block w-100">
            <Container>
              <div
                className="row"
                initial="hidden"
                animate={`${didScroll ? 'visible' : 'hidden'}`}
              >
                {teamMembersData.teammember.map((item, memberIndex) => (
                  <div key={memberIndex} className="mb-5 col-md-4 col-6">
                    <Member
                      data={item}
                      fluid={item.teamimage.imageFile.childImageSharp.fluid}
                    />
                  </div>
                ))}
              </div>
            </Container>
          </div>
        </div>
      </div>
      */}
      {/* Techs */}
      <div className="mb-md-3 d-inline-block w-100 techbanner">
        <div className="mb-5 d-inline-block w-100">
          <Container>
            <Row>
              <Col xs={12}>
                <h2 className="seo-h2">{techBannerData.techtitle}</h2>
              </Col>
              <Col md={6} lg={5}>
                <Hr className="mb-4" />
                <p className="text-gray mb-3 mb-lg-0 pr-3 pr-lg-0">
                  {techBannerData.techtext}
                </p>
              </Col>
              <Col lg={7}>
                <div className="mt-3 mt-md-0 d-inline-block w-100">
                  <div className="d-none d-xl-block">
                    <Image
                      src={techBannerData.techimage.imageFile.publicURL}
                      alt={
                        techBannerData.techimage.altText
                          ? techBannerData.techimage.altText
                          : techBannerData.techimagealt
                      }
                    />
                  </div>
                  <div className="d-none d-md-block d-xl-none">
                    <Image
                      src={techBannerData.tabletTechimage.imageFile.publicURL}
                      alt={
                        techBannerData.techimage.altText
                          ? techBannerData.techimage.altText
                          : techBannerData.techimagealt
                      }
                    />
                  </div>
                  <div className="d-md-none">
                    <Image
                      src={techBannerData.mobileTechimage.imageFile.publicURL}
                      alt={
                        techBannerData.techimage.altText
                          ? techBannerData.techimage.altText
                          : techBannerData.techimagealt
                      }
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* Company, services & work */}
      <div className="carousel-wrap d-flex w-100">
        <CarouselBodyButton data={cardsStandardData} />
      </div>
      <ProjectForm data={projectFormData} />
    </Layout>
  )
})`
  .carousel-wrap {
    padding-bottom: 85px;

    @media (min-width: 768px) {
      padding-bottom: 80px;
    }
  }

  .seo-h2 {
    font-size: 32px;
    line-height: 37px;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 47px;
    }
  }

  .techbanner {
    hr {
      height: 4px;
    }

    img {
      display: block;
      margin: 0 auto;
      width: auto;

      @media (min-width: 768px) {
        display: block;
        float: right;
        margin-top: -60px;
      }

      @media (min-width: 1440px) {
        margin-top: 0;
      }
    }
  }
`
export default Team

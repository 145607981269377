import React from 'react'
import { object } from 'prop-types'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Member = styled(
  ({ data: { title, teamPageFields }, image, className }) => (
    <div className={`${className}`}>
      <div className="member-wrapper">
        <div className="img-wrapper">
          <div
            className="decoration"
            color={teamPageFields.colorType}
            css={css`
              background: linear-gradient(
                to top left,
                transparent 0%,
                transparent 50%,
                ${teamPageFields.colorType
                  ? `${teamPageFields.colorType} 50%, ${teamPageFields.colorType} 90%`
                  : `#c06dbb 50%,
              #c06dbb 99%`}
              );
            `}
          >
            {' '}
          </div>
          <Img
            fluid={image.imageFile.childImageSharp.fluid}
            alt={image.altText ? image.altText : title}
            className="custom"
          />
        </div>
        <div className="text-wrapper bg-white">
          <h6 className="text-black font-weight-bold">{title}</h6>
          <p className="text-gray m-0">{teamPageFields.teamTitle}</p>
        </div>
      </div>
    </div>
  )
)`
  position: relative;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 577px) {
    margin-bottom: 105px;
  }

  .member-wrapper {
    position: relative;
    margin: 0 auto;
  }

  .img-wrapper {
    position: relative;
    z-index: -1;
    width: 100%;

    .custom {
      left: 4px;
      max-height: 190px;
      width: 100%;

      @media (min-width: 768px) {
        left: 10px;
        max-height: 380px;
      }
    }

    .decoration {
      height: 66.5px;
      left: 0;
      position: absolute;
      top: -4px;
      transition: all 100ms linear;
      width: 66.5px;

      @media (min-width: 768px) {
        height: 115px;
        top: -10px;
        width: 115px;
      }
    }
  }

  .text-wrapper {
    left: -1px;
    padding: 4px 18px 4px 8px;
    position: absolute;
    top: calc(100% - 16px);
    width: calc(100% - 16px);

    h6 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      padding: 9px 20px;
      margin-top: -37px;
      width: calc(100% - 51px);
    }
  }
`

Member.propTypes = {
  data: object.isRequired
}

Member.defaultProps = {
  data: {}
}

export default Member

import React from 'react'
import styled from '@emotion/styled'

import TeamShapeDesktop from '@images/team/team-desktop.svg'
import TeamShapeTablet from '@images/team/team-tablet.svg'

const Image = styled(({ className }) => {
  return (
    <div className={`${className} h-100`}>
      <div className="d-none d-xl-block"><TeamShapeDesktop /></div>
      <div className="d-none d-md-block d-xl-none"><TeamShapeTablet /></div>
    </div>
  )
})`
  position: absolute;
  top: 0;
  right: -5px;

  @media (min-width: 1440px) {
    right: 20px
  }
`


export default Image

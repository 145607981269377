import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Member from './components/Member'

function classNames(...classNames) {
  return classNames.filter(Boolean).join(' ');
}

const TeamCategory = styled(({ teamCategories, className }) => {
  let container = []

  teamCategories.edges.forEach((section) => {
    const teams = section.node.teams.edges

    teams.forEach((team, inCategoryIndex) => {
      let newTeam = {
        index: inCategoryIndex,
        length: teams.length,
        teamTitle: section.node.name,
        data: team.node,
        firstInCategory: inCategoryIndex === 0,
        lastInCategory: (inCategoryIndex + 1) === teams.length
      }
      container.push(newTeam)
    })
  })

  return (
    <div className={`${className}`}>
      <Container>
        <Row>
          {container.map((list, cardIndex) => (
            <Col xs={6} lg={4} key={cardIndex}>
              <div
                className="teamItem"
                data-color={list.data.teamPageFields.colorType}
              >
                {list.firstInCategory && (
                  <div className="teamTitle">
                    <div>
                      {list.teamTitle}{' '}
                      <span className="count">{list.length}</span>
                    </div>
                  </div>
                )}
                {!list.firstInCategory && (
                  <div
                    className={classNames(
                      'teamLine',
                      'd-lg-block',
                      cardIndex > 0 && cardIndex % 3 === 0 ? 'left-truncate' : '',
                      list.index === 1 && (cardIndex + 1) % 2 === 0 ? 'd-block' : '',
                      list.lastInCategory ? 'last-in-category' : '',
                    )}
                    data-index={list.index}
                    data-length={list.length}
                  ></div>
                )}
                <Member
                  data={list.data}
                  image={list.data.featuredImage}
                  key={cardIndex}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
})`
  .container {
    @media (min-width: 768px) {
      padding-right: 45px;
      padding-left: 45px;
    }
  }

  [data-color='#2B58CA'] {
    .count {
      color: #2b58ca;
    }
    *:before {
      background-color: #2b58ca;
    }
  }
  [data-color='#C06DBB'] {
    .count {
      color: #c06dbb;
    }
    *:before {
      background-color: #c06dbb;
    }
  }
  [data-color='#6DBF70'] {
    .count {
      color: #6dbf70;
    }
    *:before {
      background-color: #6dbf70;
    }
  }
  [data-color='#F5C141'] {
    .count {
      color: #f5c141;
    }
    *:before {
      background-color: #f5c141;
    }
  }

  .teamItem {
    @media (min-width: 768px) {
      margin: 0 auto;
      width: calc(100% - 20px);
    }

    .teamTitle {
      color: #000000;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.03em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      position: relative;
      margin-bottom: 28px;

      div {
        align-items: center;
        background-color: #fff;
        z-index: 2;
        position: relative;
        display: inline-flex;
        padding-right: 15px;
        padding-left: 0;

        span {
          //color: #2b58ca;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0.03em;
          margin-left: 8px;
        }
      }

      &:before {
        content: '';
        height: 2px;
        position: absolute;
        width: calc(100% + 4px);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .teamLine {
      display: none;
      height: 36px;
      position: relative;
      margin-bottom: 28px;

      &:before {
        content: '';
        height: 2px;
        position: absolute;
        left: -26px;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% + 26px);

        @media (min-width: 576px) {
          left: -46px;
          width: calc(100% + 50px);
        }
      }

      @media (min-width: 992px) {
        &.left-truncate:before {
          left: 0;
        }

        &.left-truncate.last-in-category:before {
          width: 100%;
        }
      }
    }
  }

  .label {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    height: 133px;
    justify-content: center;
    position: relative;
    margin: 0 auto 58px;
    width: 133px;

    span {
      flex: 0 0 100%;
      max-width: 100%;

      &.title {
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.03em;
      }

      &.count {
        font-weight: bold;
        font-size: 48px;
        line-height: 37px;
      }
    }

    div {
      height: 100%;
      opacity: 0.2;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &.business,
    &.marketing {
      padding-left: 30px;

      div {
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
      }

      span.count {
        order: 1;
      }
    }

    &.development,
    &.design {
      padding-left: 34px;

      div {
        clip-path: polygon(0 0, 0% 100%, 100% 0);
      }
    }

    &:not(.business, .marketing, .developers, .design) {
      padding-left: 34px;

      div {
        clip-path: polygon(0 0, 0% 100%, 100% 0);
      }
    }
  }
`

TeamCategory.propTypes = {
  teamCategories: object.isRequired
}

TeamCategory.defaultProps = {
  teamCategories: {}
}

export default TeamCategory

import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'gatsby'

import Hr from '@components/Hr'

import colors from '@styles/js/colors'

const Body = styled(({ data: { title, ptext, button }, className }) => (
  <div className={`${className} h-100 w-100`}>
    <Container className="h-100">
      <Row className="align-items-center h-100">
        <Col md={9} lg={7} xl={6}>
          <div className="box">
            <h1 className="text-white mb-4">{title}</h1>
            <Hr color={colors.postGreen} className="mb-4" />
            <div className="mb-4">
              {ptext.map((item, index) => (
                <p className="text-white" key={index}>
                  {item.paragraph}
                </p>
              ))}
            </div>
            <Link to={button.link}>{button.title}</Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
))`
  padding-left: 15px;
  position: relative;

  @media (min-width: 768px) {
  }

  .box {
    @media (min-width: 992px) {
    }
  }

  h1 {
    font-weight: 300;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.02em;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.02em;
    }
  }

  p {
    width: 85%;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: 1440px) {
      width: 100%;
    }
  }

  a{
    background: #F5C141;
    color: #000000;
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
    padding: 16px 48px;
    text-transform: uppercase;
    transition: all 0.3s;

    &:hover{
      background: #daac39;
      text-decoration: none;
    }
  }
`

Body.propTypes = {
  data: object.isRequired
}

Body.defaultProps = {
  data: {}
}

export default Body

import React from 'react'

// compositions
import Team from '@compositions/Team'

const TeamPage = () => {
  return <Team />
}

export default TeamPage

import React from 'react'
import { func } from 'prop-types'

import ArrowPrev from '@images/left-arrow.svg'

const ButtonPrev = ({ onClick }) => {
  return (
    <button className={`slick-arrow slick-prev`} onClick={onClick}>
      <ArrowPrev />
    </button>
  )
}

ButtonPrev.propTypes = {
  onClick: func.isRequired
}

ButtonPrev.defaultProps = {
  onClick: () => null
}

export default ButtonPrev

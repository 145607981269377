import React from 'react'
import { func } from 'prop-types'

import ArrowNext from '@images/right-arrow.svg'

const ButtonNext = ({ onClick }) => {
  return (
    <button className={`slick-arrow slick-next`} onClick={onClick}>
      <ArrowNext />
    </button>
  )
}

ButtonNext.propTypes = {
  onClick: func.isRequired
}

ButtonNext.defaultProps = {
  onClick: () => null
}

export default ButtonNext
